




























































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    dialog: {
      default: true,
      type: Boolean
    }
  },
  setup() {
    const myListForDirector = [
      {
        listTitle: "About Board Of Director",
        listContent:
          "The board of directors is composed of founders and enthusiasts. Ms Hsu Yuk King is the chairman and Sandy Lee is the director. We have also set up Compliance Department and Raymond Li is in charge of the department."
      },
      {
        listTitle: "Members",
        listContent:
          "In the future, we hope to attract a wide range of people, such as social leaders, business leaders and enthusiastic children's service professionals. At the same time, we hope to set up an advisory bureau within the next three years to make recommendations on the development and supervision of the foundation. Members of this advisory board are also from a wide range of people."
      },
      {
        listTitle: "Income & Investment",
        listContent:
          "In order for service to be continuing, income must be recurrent. Therefore, the stable investment and income are also the direction of future development."
      },
      {
        listTitle: "Various departments are under the board of directors",
        listContent:
          "The board of directors is composed of founders and enthusiasts. Ms Hsu Yuk King is the chairman and Sandy Lee is the director. We have also set up Compliance Department and Raymond Li is in charge of the department."
      }
    ];
    const variousDepartment = [
      {
        listTitle:
          "Administration department : responsible for administrative support of various service units.",
        listContent: ""
      },
      {
        listTitle:
          "Finance and Supervision Department : responsible for the budget of the various service units and supervision of the budget with the actual revenue and expenditure.",
        listContent: ""
      },
      {
        listTitle: "Service Units : provide services to children directly, services including:",
        listContent: ""
      }
    ];

    return { myListForDirector, variousDepartment };
  }
});
